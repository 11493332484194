<template>
  <b-tabs content-class="mt-3">
    <seo-tab
        ref="seoTab"
        :entity-id="entityId"
        entity-type="blogPost"
    />
    <images-tab
        @refresh-data="refreshTabData"
        :data="tabsData.images"
        :entity-id="entityId"
        :entity-type="entityType"
    />
    <files-tab
        @refresh-data="refreshTabData"
        :data="tabsData.files"
        :entity-id="entityId"
        :entity-type="entityType"
    />
<!--    <related-posts-tab
      @refresh-data="refreshTabData"
      :data="tabsData.relatedPosts"
      :entity-id="entityId"
      :language-key="language"
    />-->
    <log-tab
      :entity-id="entityId"
      :entity-type="entityType"
    />
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import seoTab from '@/views/pages/_components/_tab/_shared/_update/seo.vue'
import imagesTab from '@/views/pages/_components/_tab/_shared/_update/images.vue'
import filesTab from '@/views/pages/_components/_tab/_shared/_update/files.vue'
//import relatedPostsTab from "@/views/pages/_components/_tab/_post/_update/relatedPosts"
import logTab from '@/views/pages/_components/_tab/_log/_list.vue'

export default {
  components: {
    BTabs,
    seoTab,
    imagesTab,
    filesTab,
    //relatedPostsTab,
    logTab,
  },
  props: {
    attributes: [],
    languageKey: '',
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tabsData: [],
      language: '',
    }
  },
  watch: {
    attributes: {
      handler(value) {
        let tmp = {
          images: [],
          videos: [],
          files: [],
          relatedPosts: [],
        }

        if (value.images.length) tmp.images = value.images
        if (value.videos.length) tmp.videos = value.videos
        if (value.files.length) tmp.files = value.files
        if (value.relatedPosts.length) tmp.relatedPosts = value.relatedPosts

        this.tabsData = tmp
      }
    },
    languageKey: {
      handler(value) {
        this.language = value
      }
    }
  },
  methods: {
    refreshTabData() {
      this.$emit('refresh-tabs-data')
    }
  }
}
</script>

<style scoped>

</style>
